import './styles.css';

import { Link } from 'react-router-dom';
import React from 'react'
import useFetch from '../../../hooks/use-fetch';

const Articles: React.FC = () => {
    const { error, isLoading, articles } = useFetch({ name: "articles" });
    return (
        <div className="main-container-media ">
            <div className='right-column-media'>
                <div className='article-bible-verse'>
                    <h1>Articles</h1>
                    <p>Explore a variety of insightful articles covering topics on faith, Christian living, and spiritual growth</p>
                    <div className='button-submit-article'>
                        <a href='/articles'><button>READ MORE</button></a>
                    </div>
                </div>

            </div>
            <div className='left-column-media'>
               
                {articles.filter((value: any, index: number) => (index < 5)).map((article: any) => (
                    <div className='articles' key={article.id}>
                        <Link
                            to={`/blog/${article.id + 1}`}
                            key={article.id}
                            state={article}
                            style={{
                                height: '100%',
                                textDecoration: 'none',
                                color: 'black',
                            }}
                            onClick={() => {window.scrollTo(0,0) }}
                        >
                            <img src={article.imageUrl} />
                            <p>{article.title}</p>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Articles;