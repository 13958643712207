import About from "./About";
import Articles from "./Articles";
import Bridge from "../../components/brigde";
import Events from "./Events";
import Footer from "../../components/footer";
import { Fragment } from "react/jsx-runtime";
import HomeIndex from "./Home";
import Ministries from "./Ministries";
import NewsLetter from "./NewsLetter";
import Sermons from "./Sermons";
import Space from "../../components/space";
const Main: React.FC = () => {

    return (
        <>
            <HomeIndex />
            <Space />
            <About />
            <Space />
            <Ministries />
            <Space />
            <Sermons />
            <Space />
            <Articles />
            <Space />
            <Bridge />
            <Space />
            <Events />
            <Space />
            <NewsLetter />
            <Space />
            <Footer />
        </>
    )
};

export default Main;