import './styles.css';

import React from 'react';

const Bridge: React.FC = () => {
    return (
        <div className='main-container-brigde'>
            <div className='heading-brigde'>
                <div className='heading-brigde-column' style={{ borderBottom: 'solid 5px rgb(222, 220, 220)' }}>
                    <h3>
                        Through events, social media, and newsletters, we are dedicated to spreading God's loving word globally, connecting with people from all walks of life. Our outreach initiatives aim to share the message of hope and redemption, reaching diverse communities and cultures with the transformative power of God's love and grace. Join us as we engage in purposeful activities and communication channels to proclaim the Gospel to every nation, sharing the life-changing truth of Jesus Christ with compassion and conviction. Together, let's illuminate the world with His loving word and inspire hearts to embrace faith and love.
                    </h3>
                </div>
                <div className='heading-brigde-column' >
                    <h1>And <u>Proclaiming</u> His Loving Word to the Nations ...</h1>
                </div>
            </div>
        </div>
    )
}

export default Bridge;