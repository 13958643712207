import './styles.css';

import React, { useState } from 'react'

import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Modal } from '@mui/material';

interface NavbarProps  {
    pageName?: string
}
const Navbar = (props: NavbarProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isMediaOpen, setIsMediaOpen] = useState(false);
    const [isMinistriesOpen, setIsMinistriesOpen] = useState(false);
    return (
        <>
            <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                style={{
                    backgroundColor: '#0a0000',
                    color: 'white',
                    opacity: '1',
                    visibility: 'visible',
                    overflow: 'hidden',
                    overflowY: 'scroll'
                }}
            >
                <div style={{ height: '100%' }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        padding: '29px'
                    }}>
                        <CloseIcon
                            onClick={() => setIsModalOpen(!isModalOpen)}
                            style={{
                                fontSize: "50px",
                            }}
                        />
                    </div>
                    <ul className='hamburger-menu-items'>
                        <li><a href='/' >Home</a></li>
                        <li><a href='/about' onClick={() => setIsModalOpen(!isModalOpen)}>About</a></li>
                        <li onClick={() => setIsMinistriesOpen(!isMinistriesOpen)}>Ministries
                            {
                                isMinistriesOpen ? <ul className='hamburger-menu-ul'>
                                    <li><a href='/services' onClick={() => setIsModalOpen(!isModalOpen)}>Worship Services</a></li>
                                    <li><a href='/groups' onClick={() => setIsModalOpen(!isModalOpen)}>Church Groups</a></li>
                                    <li><a href='/community' onClick={() => setIsModalOpen(!isModalOpen)}>Community Impact</a></li>
                                    <li><a href='/bible-study' onClick={() => setIsModalOpen(!isModalOpen)}>Bible Study</a></li>
                                </ul> : <></>
                            }
                        </li>
                        <li onClick={() => setIsMediaOpen(!isMediaOpen)}>Media
                            {
                                isMediaOpen ? <ul className='hamburger-menu-ul'>
                                    <li><a href='https://www.youtube.com/@ibadayakiswahilicolumbus3202' onClick={() => setIsModalOpen(!isModalOpen)}>Sermons</a></li>
                                    <li><a href='/articles' onClick={() => setIsModalOpen(!isModalOpen)}>Articles</a></li>
                                </ul> : <></>
                            }
                        </li>
                        <li><a href='/events' onClick={() => setIsModalOpen(!isModalOpen)}>Events</a></li>

                        <li><a href='#footer' onClick={() => setIsModalOpen(!isModalOpen)}>Contact</a></li>
                        <li><a href='https://secure.myvanco.com/YGPN/campaign/C-YHA8'>Give</a></li>
                    </ul>
                </div>
            </Modal>
            <div className="main-container-navbar">
                {
                    
                        <a href='/' className='left-column-navbar' style={{ textDecoration: 'none' }}>
                        {
                            props.pageName === 'Home' ? <></> : <img src={require('../../assets/logo-iyk.jpeg')} />
                            
                        }
                            {    props.pageName !== 'Home' ? <><h1 style={{ color: 'black' }}>Ibada ya Kiswahili<br></br> Columbus, Ohio</h1> </>
                                    : <></>
                            }
                        </a>
                }
                {/* <div className='middle-column-navbar'>

            </div> */}
                <div className='right-column-navbar'>
                    <>
                        {
                            // (window.innerWidth <= 1024) ?
                            <MenuIcon
                                onClick={() => setIsModalOpen(!isModalOpen)}
                                style={{
                                    cursor: 'pointer',
                                    width: '32px',
                                    height: '32px',
                                    color: props.pageName === 'Home' ? 'white' : 'black'
                                }}
                            />
                            // :
                            // <ul className='nav-items'>
                            //     <li><a href='/'>Home</a></li>
                            //     <li><a href='/#about'>About</a></li>
                            //     <li><a href='/#media'>Media</a></li>
                            //     <li><a href='https://secure.myvanco.com/YGPN/campaign/C-YHA8'>Give</a></li>
                            //     <li><a>I am New!</a></li>
                            // </ul>
                        }
                    </>
                </div>
            </div>
        </>
    )
}

export default Navbar;