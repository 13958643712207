import './styles.css';

import Footer from '../../components/footer';
import { Link } from 'react-router-dom';
import Navbar from '../../components/navbar';
import React from 'react';

const MINISTRIES = [
    {
        "title": "Sunday Services",
        "description": "Join us every Sunday for an uplifting worship experience filled with inspiring music and powerful sermons that encourage your faith.",
        "button-title": "LEARN MORE",
        "link": "sunday-services"
    },
    {
        "title": "Prayer Meetings",
        "description": "Gather weekly to seek God's guidance, share burdens, and pray together for our community and the world, uniting in faith.",
        "button-title": "LEARN MORE",
        "link": "prayer-meetings"
    },
    {
        "title": "Confirmation",
        "description": "Deepen your commitment to Christ through the sacraments of confirmation and baptism, publicly affirming your faith within the community.",
        "button-title": "LEARN MORE",
        "link": "confirmation"
    },
    {
        "title": "Overnight Prayers",
        "description": "Join us for a night of reflection and worship during the quiet hours, seeking God's presence and breakthroughs through fervent prayer.",
        "button-title": "LEARN MORE",
        "link": "overnight-prayers"
    },
    {
        "title": "Morning Glory",
        "description": "Start your day with spiritual renewal as we dive into Scripture, engage in prayer, and seek God's wisdom and guidance for the day ahead.",
        "button-title": "LEARN MORE",
        "link": "morning-glory"
    },
    {
        "title": "Baptism",
        "description": "Publicly declare your faith and commitment to Christ by participating in the sacrament of baptism, a transformative and joyful experience.",
        "button-title": "LEARN MORE",
        "link": "baptism"
    },
];


const Services: React.FC = () => {
    return (
        <>
            <Navbar />
            <div className="main-container-services">
                <div className='heading-service'>
                    <div className='left-column-service'>
                        <img src={require('../../assets/child-bible.jpg')} alt="" className='left-column-service-content' />
                        <img src={require('../../assets/bible-study.jpg')} alt="" className='left-column-service-content' />
                        <img src={require('../../assets/church-groups.jpg')} alt="" className='left-column-service-content' />
                        <img src={require('../../assets/community-impact.jpg')} alt="" className='left-column-service-content' />
                    </div>
                    <div className='right-column-service'>
                        <h1>Explore Our Ministries</h1>
                        <p>Crafted to nurture faith, strengthen relationships, and empower individuals in their Christian calling.</p>
                    </div>
                </div>
                <div className='ministries-bible-verse '>
                    <p>
                        "Therefore go and make disciples of all nations, baptizing them in the name of the Father and of the Son and of the Holy Spirit..."
                    </p>
                    <h5>
                        Matthew 28:19, NIV
                    </h5>
                </div>
                <div className='main-container-services-items'>
                    {
                        MINISTRIES.map((item, index: any) =>
                            <div className='service-item'>
                                <img src={require('../../assets/community-impact.jpg')} />
                                <h1>{item.title}</h1>
                                <p>{item.description}</p>
                                <Link to={`${item.link}`}
                                    onClick={() => { window.scrollTo(0, 0) }}
                                >
                                    <button>{item['button-title']}</button>
                                </Link>
                            </div>
                        )
                    }
                </div>
            </div>
<Footer />
        </>
    )
}


export default Services;