import './styles.css';

import Footer from '../../components/footer';
import { Link } from 'react-router-dom';
import Navbar from '../../components/navbar';
import React from 'react'
import { stripLongString } from '../../helpers';
import useFetch from '../../hooks/use-fetch';

const Events: React.FC = () => {
    const { error, isLoading, events } = useFetch({ name: "events" });
    if (isLoading) {
        return (
            <>

            </>
        );
    }

    if (error) {
        return (
            <>
                <div>Error: {error}</div>
            </>
        );
    }
    return (
       <>
       <Navbar />
            <div className="main-container-events-page">
                <div className='image-title'>
                    <div className='titles'>
                        <h1>Events</h1>
                        <h2>Get to know what is happening in our  community!</h2>
                    </div>
                    <div className='images-box'>
                        <div className='double-images'>
                            <img src={require('../../assets/community-impact.jpg')} />
                            <img src={require('../../assets/child-bible.jpg')} />
                        </div>
                        <div className='single-image'>
                            <img src={require('../../assets/church-groups.jpg')} />
                        </div>
                    </div>
                </div>
                
                {
                    
                    [...events.reverse()].map((event: any) =>
                        <Link 
                            to={`/event/${event.id}`} 
                            key={event.id} 
                            className='event-link'
                            state={event}
                        >
                        <div className='content-card'>
                            <div className='event-card'>
                                <img src={event.imageUrl} />
                                <div className='event-details'>
                                    <h2>{event.title}</h2>
                                    <p>{event.date} from {event.time}</p>
                                    <p className='event-description-p'>{stripLongString(event.description, 30)}</p>
                                    <button>READ MORE</button>
                                </div>
                            </div>
                        </div>
                        </Link>
                        )
                }
            </div>
            <Footer />
       </>
    )
}

export default Events;