import { useEffect, useState } from 'react';

interface UseFetchProps {
    name: string
}

const useFetchContent = (props: UseFetchProps) => {
    const bufferObj = {} as any;
    const [content, setContent] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchContent = async () => {
            setIsLoading(true);
            try {
                // Make API call to fetch content: return to this guy.
                const response = await fetch(`https://iyk-back-end-fc13ca0421c5.herokuapp.com/${props.name}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch content');
                }
                const data = await response.json();
                setContent(data);
            } catch (error: any) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchContent();
    }, []);
    
    bufferObj[props.name] = content;
    return { ...bufferObj, error, isLoading };
};

export default useFetchContent;
