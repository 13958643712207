import {
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

import AboutPage from "./website-pages/about/about";
import BibleStudy from './website-pages/bible-study';
import BlogPage from "./website-pages/blog";
import BlogPostPage from "./website-pages/blogpost";
import Community from './website-pages/community';
import Dashboard from "./dashboard";
import EventPage from "./website-pages/event";
import Events from "./website-pages/events";
import Groups from './website-pages/groups';
import Main from "./website-pages/main";
import ResponsiveForm from "./components/form";
import Services from './website-pages/ministries';
import TwoSidedPage from "./components/two-sided-page";
import servicesData from "./helpers";

function App() {
  return (
    <Router >
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/groups" element={<Groups />} />
        <Route
          path="/services"
          element={<Services />}
        />
        <Route
          path="/community"
          element={<Community />}
        />
        <Route path="/bible-study" element={<BibleStudy />} />
        <Route path="/events" element={<Events />} />
        <Route path="/articles" element={<BlogPage/>} />
        <Route path="/blog/:id" element={<BlogPostPage />} />
        <Route path="/event/:id" element={<EventPage />} />
        <Route path="/form" element={<ResponsiveForm />} />
        <Route path="/about" element={<AboutPage />} />
        {servicesData.map((service, index) => (
          <Route
            key={index}
            path={service.path}
            element={<TwoSidedPage
              title={service.title}
              description={service.description}
              haveLink={service.haveLink}
              buttonTitle={service.buttonTitle}
              imageUrl={service.imageUrl}
            />}
          />
        ))}
      </Routes>
    </Router>
    // <Router>
    //   <Dashboard />
    // </Router>
    // <ResponsiveForm />
  );
}

export default App;
