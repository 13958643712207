import './styles.css';

import Footer from '../../components/footer';
import { Link } from 'react-router-dom';
import Navbar from '../../components/navbar';
import React from 'react';

const Community: React.FC = () => {

    const outreachPrograms = [
        {
            imgSrc: 'community-impact.jpg',
            title: 'Food Pantry',
            description: 'Our Food Pantry provides essential food supplies to those in need within our community, ensuring access to nutritious groceries in a welcoming and supportive environment.',
            buttonTitle: 'EXPLORE',
            link: 'food-pantry'
        },
        {
            imgSrc: 'child-bible.jpg',
            title: 'Youth Kids Soccer',
            description: 'Join our youth soccer program where children can learn and grow in a supportive environment, fostering both skill development and a passion for teamwork and sportsmanship.',
            buttonTitle: 'EXPLORE',
            link: 'youth-kids-soccer'
        },
        {
            imgSrc: 'bible-study.jpg',
            title: 'Neighborhood Clean-up',
            description: 'Join us for our Neighborhood Cleanup events, where volunteers come together to pick up litter and remove debris, fostering a cleaner and safer community for everyone.',
            buttonTitle: 'EXPLORE',
            link: 'neighborhood-cleanup'
        },
        {
            imgSrc: 'church-groups.jpg',
            title: 'Car Wash',
            description: 'Our Car Wash service is a community event that not only leaves your vehicle sparkling clean but also raises funds for various church activities and outreach programs.',
            buttonTitle: 'EXPLORE',
            link: 'car-wash'
        }
    ];

    return (
        <>
            <Navbar />
            <div className="main-container-community">
                <div className='community-heading-and-pictures'>
                    <div className='heading-community'>
                        <h1>Community Groups</h1>
                        <p>Our Community Outreach program extends Christ's love through initiatives like food drives and homeless outreach, meeting physical and spiritual needs. By partnering with local organizations, we aim to be a beacon of hope, making a lasting impact in our community.</p>
                    </div>
                    <img src={require('../../assets/church-groups.jpg')} alt="Community" />
                    <div className='community-pictures'>
                        <img src={require('../../assets/child-bible.jpg')} alt='Bible Study' style={{ width: '90%' }} />
                        <img src={require('../../assets/bible-study.jpg')} alt='Bible Study' />
                    </div>
                </div>

                <div className='bridge-content'>
                    <h1><u>Delve</u> More in How We Serve the Community</h1>
                    <h3>Our mission is to serve the our community with joy and dedication, ensuring that every interaction brings pleasure and fulfillment to those we assist</h3>
                </div>

                <div className='community-outreach-programs'>
                    {outreachPrograms.map((program, index) => (
                        <div className='outreach-program' key={index}>
                            <img src={require(`../../assets/${program.imgSrc}`)} alt={program.title} />
                            <h1>{program.title}</h1>
                            <p>{program.description}</p>
                            <Link 
                                to={program.link} 
                                onClick={() => { window.scrollTo(0, 0) }}
                            >
                                <button>{program.buttonTitle}</button>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Community;

