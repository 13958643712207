import './styles.css';

import React from 'react';

const Sermons: React.FC = () => {
    return (
        <div id='media' className="main-container-sermons ">
            <div className='right-column-sermons'>
                <img src={require('../../../assets/church-groups.jpg')} className='sermon' />
            </div>
            <div className='left-column-sermons'>
                <div className='sermon-title'>
                    <h1>Sermons</h1>
                    <p>Explore a diverse collection of powerful sermons that inspire, uplift, and deepen your faith journey</p>
                    <div className='button-submit-sermon'>
                        <a href='https://www.youtube.com/@ibadayakiswahilicolumbus3202'> <button>WATCH</button></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sermons;