import { useState } from "react";

const useGePaginatedContent = (articles: any, postsPerPage: number) => {
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = articles.slice(indexOfFirstPost, indexOfLastPost);

    const paginate = (pageNumber: any) => setCurrentPage(pageNumber);
    return { currentPosts, postsPerPage, paginate };
}

export default useGePaginatedContent;