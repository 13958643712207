import './styles.css';

import Navbar from '../../components/navbar';
import React from 'react';
import { useLocation } from 'react-router-dom';

const EventPage = () => {
    const { state } = useLocation();

    return (
        <>
            <Navbar />
            <div className="event-item-container">
                {/* <h2>{"Event >>"}</h2> */}
                <div className="event-item-image">
                    <img src={state.imageUrl} alt="Event Image" />
                </div>
                <div className="event-item-details">
                    <h1 className="event-item-title">{state.title}</h1>
                    <div style={{ marginTop: 10 }}>
                        <p className='event-item-date' style={{ display: 'inline', color: 'grey', fontWeight: 900 }}>Date:</p><p className="event-item-date" style={{ display: 'inline' }}> {state.date}</p>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <p className='event-item-date' style={{ display: 'inline', color: 'grey', fontWeight: 900 }}>Location:</p><p className="event-item-date" style={{ display: 'inline' }}> {state.location}</p>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <p className='event-item-date' style={{ display: 'inline', color: 'grey', fontWeight: 900 }}>Description:</p><p className="event-item-description" style={{ display: 'inline' }}> {state.description}</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EventPage;
