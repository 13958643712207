import './styles.css';

import Footer from '../../components/footer';
import Navbar from '../../components/navbar';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { utils } from '../../utils'

const BlogPostPage = () => {

    const { state } = useLocation();

    return (
        <>
        <Navbar />
            <div className="blog-post-container">
                <div className="blog-post-details">
                    <h1 className="blog-post-title">{state.title}</h1>
                    <p className="blog-post-author">{state.author} | {utils.formatDateTime(state.date).formattedDate} at {utils.formatDateTime(state.date).formattedTime}</p>
                </div>
                <div className="blog-post-content">
                    <img src={state.imageUrl} alt="Post Image" />
                    <div dangerouslySetInnerHTML={ { __html: state.content } }/>
                    <br />
                </div>
            </div>
            <Footer />
        </>
    );
}

export default BlogPostPage;
