import { Fragment } from "react/jsx-runtime";

const  Space = () => {
    return (
        <Fragment>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </Fragment>
    )
}

export default Space;