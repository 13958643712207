import './styles.css';

import React, { useState } from 'react';
import { removeHTMLTagsAndURLs, stripLongString } from '../../helpers';

import Footer from '../../components/footer';
import { Link } from 'react-router-dom';
import Navbar from '../../components/navbar';
import useFetch from '../../hooks/use-fetch';
import useGetPaginatedContent from '../../hooks/use-get-paginated-content';
import { utils } from '../../utils'

const BlogPage = () => {
    const { error, isLoading, articles } = useFetch({ name: "articles" });

    const { currentPosts, postsPerPage, paginate } = useGetPaginatedContent(articles, 4);

    if (isLoading) {
        return <div></div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <>
            <Navbar />
            <div className="container">
                <div className="posts">
                    <h1>Articles</h1>
                    {currentPosts.map((article: any) => (
                        <Link to={`/blog/${article.id}`} key={article.id} className='post-link-article' state={article}>
                            <div className="post" key={article.id}>
                                <div className="post-details">
                                    <h2 className="post-title">{article.title}</h2>
                                    <p className="post-author">{article.author} | {utils.formatDateTime(article.date).formattedDate} at {utils.formatDateTime(article.date).formattedTime}</p>
                                    <p className="post-summary">{stripLongString(removeHTMLTagsAndURLs(article.content), 50)}</p>
                                </div>
                                <div className="post-image">
                                    <img src={article.imageUrl} alt="Post Image" />
                                </div>
                            </div>
                            <div className='line-div'>
                                <hr />
                            </div>
                        </Link>
                    ))}
                </div>
                <div className="pagination">
                    {Array.from({ length: Math.ceil(articles.length / postsPerPage) }, (_, index) => (
                        <a key={index} onClick={() => paginate(index + 1)} href="#!">
                            {index + 1}
                        </a>
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
}

export default BlogPage;



