import './styles.css';

import React, { useState } from 'react';

import Footer from '../../components/footer';
import Navbar from '../../components/navbar';

const AboutPage: React.FC = () => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const handleToggle = (index: number) => {
        setActiveIndex(activeIndex === index ? null : index);
    };
    const beliefs = [
        {
            title: 'GOD',
            content: 'We believe in one God, who is eternal and exists in three persons: Father, Son, and Holy Spirit. God is the Creator and Sustainer of all things, and He is sovereign over the universe. His love and grace are immeasurable, and His mercy endures forever. Through His divine plan, He seeks to redeem and restore humanity to a relationship with Him.'
        },
        {
            title: 'JESUS CHRIST',
            content: 'We believe in Jesus Christ, the Son of God, who became incarnate, lived a sinless life, died for our sins, and rose again, offering salvation to all who believe in Him. Jesus is both fully God and fully man, and through His sacrifice, He reconciles us to God. His resurrection assures us of eternal life and victory over sin and death. He is our Lord, Savior, and the ultimate example of love and obedience to God.'
        },
        {
            title: 'HOLY SPIRIT',
            content: 'We believe in the Holy Spirit, who dwells within believers, empowering them to live a godly life and equipping them for service. The Holy Spirit is our Comforter, Guide, and Advocate, providing wisdom and discernment. He convicts the world of sin, righteousness, and judgment, leading people to repentance. Through the Holy Spirit, we receive spiritual gifts that build up the body of Christ and advance God’s kingdom.'
        },
        {
            title: 'THE BIBLE',
            content: 'We believe the Bible is the inspired Word of God, authoritative and infallible in all its teachings. It is a divine revelation that guides us in all matters of faith and practice. The Scriptures are useful for teaching, rebuking, correcting, and training in righteousness. Through the Bible, God reveals His character, His will, and His redemptive plan for humanity.'
        },
        {
            title: 'SALVATION',
            content: 'We believe that salvation is a gift from God, received by grace through faith in Jesus Christ, leading to eternal life. It is not earned by human effort but given freely to those who trust in Christ’s atoning sacrifice. Salvation brings forgiveness of sins, reconciliation with God, and the promise of eternal life with Him. It transforms our lives, enabling us to live in obedience and service to God.'
        }
    ];

    const leadershipData = [
        // {
        //     name: 'Rev. Gwakisa Mwaipopo',
        //     position: 'Pastor',
        //     image: require('../../assets/rev-mwaipopo.jpeg')
        // },
        {
            name: 'Immanuel Lyatuu',
            position: 'Chairperson',
            image: require('../../assets/immanuel-lyatuu.jpeg')
        },
        {
            name: 'Jackson Lyimo',
            position: 'Secretary',
            image: require('../../assets/jack-lyimo.jpeg')
        }
        // Add more leadership data as needed
    ];


    
    return (
        <>
        <Navbar />
            <div className="about-page">
                <section className="section">
                    <h4>We are:</h4>
                    <h1>A church that celebrates the Swahili heritage while fostering spiritual growth...</h1>
                    <h3>Dedicated to nurturing faith through the rich cultural and linguistic heritage of Swahili speakers, fostering a vibrant community that celebrates diversity and unity in our shared journey of spiritual growth.</h3>
                </section>
                <img src={require('../../assets/iyk-group.jpeg')} />
                <section className="section">
                    <h2>The History of the Church:</h2>
                    <p>The Ibada ya Kiswahili (IYK) emerges as a sincere spiritual response stemming from the culture and language of Swahili. In May 2009, within the Lutheran Churches of Ascension and Gethsemane in Columbus, Ohio, IYK blossomed as a beacon of hope. It was a deliberate effort to unite Swahili speakers under a common faith and identity. Its inception was a call to serve spiritually and socially within a religious context, aiming not only to build a bridge of communication transcending geographical boundaries but also touching humanity, tradition, and faith through the Swahili language.</p>
                    <p>Under the guidance of Rev. Karen Asmus-Alsnauer and Mandy Olsen in 2009, the seeds of hope were sown. The first Swahili service took place in May 2009, marking the beginning of a spiritual journey rooted in a language rich in history and culture. Their objective was clear: to foster deep fellowship where the voices of believers could freely flow in their cherished Swahili language. Their leadership served as a guiding light on the mission journey for Swahili-speaking Americans, laying a solid foundation for a blossoming and thriving congregation, continuing to shine as a symbol of unity and continuity of our faith.</p>
                </section>

                <section className="section">
                    <h2>Vision:</h2>
                    <p>Our vision is to cultivate a vibrant, inclusive community where Swahili-speaking individuals can deeply connect with their faith and each other, transcending geographical boundaries through the rich and historical language of Kiswahili. By fostering unity, tradition, and spiritual growth, we aim to build lasting bridges of communication and understanding, shining as a beacon of hope and continuity in our collective journey of faith.</p>
                </section>

                <section className="section">
                    <h2>Mission:</h2>
                    <p>Our mission is to serve and empower the Swahili-speaking community through spiritually enriching services, fostering deep fellowship and cultural connection. We strive to provide a welcoming space where individuals can express their faith freely in their cherished Kiswahili language, supporting their spiritual and social needs, and building a strong foundation for unity, tradition, and continuous growth in faith.</p>
                </section>

                <section className="section">
                    <h2>Our Beliefs:</h2>
                    <p>Our beliefs are centered on the foundational tenets of Christianity. We affirm the existence of one eternal God in three persons: Father, Son, and Holy Spirit. We believe in Jesus Christ, the Son of God, who lived a sinless life, died for our sins, and rose again, offering salvation to all who believe in Him. We acknowledge the Holy Spirit's presence within believers, empowering them for a godly life and service. We uphold the Bible as the inspired, authoritative, and infallible Word of God. Lastly, we believe that salvation is a divine gift received by grace through faith in Jesus Christ, leading to eternal life.</p>
                    <div className="accordion">
                        {beliefs.map((belief, index) => (
                            <div className="accordion-item" key={index}>
                                <button
                                    className="accordion-button"
                                    onClick={() => handleToggle(index)}
                                >
                                    {belief.title}
                                    <span className="accordion-arrow">
                                        {activeIndex === index ? '▲' : '▼'}
                                    </span>
                                </button>
                                <div className={`accordion-content ${activeIndex === index ? 'active' : ''}`}>
                                    <p>{belief.content}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>

                {/* <section className="section">
                    <h2>Biography of the Pastor:</h2>
                    <p>Rev. Gwakisa Mwaipopo's biography encapsulates a life dedicated to faith, service, and leadership. As the youngest son of Rev. Andrew and Edina Mwaipopo, his journey has been a testament to unwavering dedication and spiritual commitment. Ordained in 2012, he has served in various pastoral roles, from guiding Mjimwema Parish to shepherding Bethania Sub-Parish, all in Dar es Salaam, Tanzania, demonstrating adaptability and resilience while nurturing the faith of congregants.</p>
                    <p>Throughout his ministry, Rev. Mwaipopo has exhibited a passion for unity and empowerment. From his involvement in mission and evangelism outreach to serving as a coordinator for partnership and interfaith initiatives, he has tirelessly worked to foster understanding and support marginalized communities. His recent tenure as Visiting Pastor at Ibada ya Kiswahili, Columbus underscored his commitment to enriching the lives of the Swahili-speaking community with pastoral care and spiritual guidance. Now, as Pastor and Mission Developer at Gethsemane Lutheran Parish, IYK's parent church, he embarks on a new chapter, poised to lead with a vision of growth, unity, and spiritual depth, continuing his journey of service with unwavering faith and love.</p>
                </section> */}

                <section className="section">
                    <h2>Church Leadership:</h2>
                    <p>Our church leadership comprises of dedicated individuals who embody the values of service, commitment, and spiritual guidance within the community. Led by the Holy Spirit, our leaders provide direction, support, and inspiration to the congregation. Their unwavering dedication and exemplary leadership foster a vibrant and nurturing environment where members can grow spiritually and find encouragement on their faith journey. Through their diverse talents and servant hearts, the church leadership cultivates unity, discipleship, and a deeper connection with God among all who seek fellowship within the community.</p>
                    
                <div className="church-leadership">
                    <div className="leadership-cards">
                        {leadershipData.map((leader, index) => (
                            <div className="leadership-card" key={index}>
                                <img src={leader.image} alt={leader.name} className="leader-image" />
                                <h3>{leader.name}</h3>
                                <p>{leader.position}</p>
                            </div>
                        ))}
                    </div>
                </div>
                </section>
            </div>
        <Footer />
        </>
    );
};

export default AboutPage;
