import './styles.css';

import React from 'react';

const About: React.FC = () => {
    return (
        <div className='main-container-about' role='region' aria-label='Homepage' id='about'>
            
            <div className='left-column-about' role='region' aria-label='Left column'>
                <div className='bible-verse'>
                    <h1>&#10077;</h1><br />
                    <p> Behold, how good and how pleasant it is for brethren to dwell together in unity!</p><br />
                    <h1>&#10078;</h1>
                    <h3>Psalm 133: 1, KJV</h3>
                </div>
            </div>
            <div className='right-column-about' role='region' aria-label='Right column'>
                <div className='title-about'>
                    <h2>Karibu!</h2>
                    <h3>We're glad you're here...</h3>
                </div>
                <div className='content-about'>
                    <p>In the spirit of simplicity and devotion, Ibada ya Kiswahili embodies Jesus' humble mission to reveal God's love to all. Grounded in the teachings of the Bible, we are inspired to embrace our calling, offering hope to those yet to experience the depths of God's grace. Our community is built upon the firm foundation of fellowship, spiritual growth, and service to others, echoing Jesus' own example of love and compassion.</p>
                    <p>With members spread across various locations in Ohio, U.S. and the world at large, our community comes together in worship and fellowship, while smaller groups meet throughout the week for deeper connection and spiritual enrichment. We invite you to join us on this journey of faith, as we strive to be a beacon of light and love, sharing God's transformative message within the Swahili-speaking community and beyond as Psalm 133: 1 entails.</p>
                    <div className='lead-pastor'>
                        {/* <h3>Rev. Gwakisa Mwaipopo</h3>
                        <h4>Lead Pastor</h4> */}
                        <a href='/about' style={{ textDecoration: 'none !important' }}><button style={{height: 40, fontWeight: 500, fontSize: 16}}>{"Our Story >>"}</button></a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;