import './styles.css';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';

import Footer from '../footer';
import Navbar from '../navbar';

interface FormData {
    fullName: string;
    email: string;
    phoneNumber: string;
    otherInput: string;
}

const Modal = ({ message, onClose }: { message: string; onClose: () => void }) => {
    return (
        <div className="modal-backdrop">
            <div className="modal">
                <h2>{message}</h2>
                {message === "Form sent successfully!" && (
                    <button onClick={onClose}>Close</button>
                )}
            </div>
        </div>
    );
};

const ResponsiveForm = (props: any) => {
    const { state } = useLocation();
    const navigate = useNavigate();

    const [formData, setFormData] = useState<FormData>({
        fullName: '',
        email: '',
        phoneNumber: '',
        otherInput: ''
    });

    const [errors, setErrors] = useState<Partial<FormData>>({});
    const [showModal, setShowModal] = useState(false); // For showing the modal
    const [modalMessage, setModalMessage] = useState("Loading..."); // Modal message

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const errors = validateFormData(formData);
        if (Object.keys(errors).length === 0) {
            // Show loading modal
            setShowModal(true);
            setModalMessage("Sending the form...");

            // Simulate sending form and wait for 5 seconds
            setTimeout(() => {
                setModalMessage("Form sent successfully!");
            }, 2000);
        } else {
            setErrors(errors);
        }
    };

    const validateFormData = (data: FormData): Partial<FormData> => {
        const errors: Partial<FormData> = {};
        if (!data.fullName.trim()) {
            errors.fullName = 'Fullname is required';
        }
        if (!/\S+@\S+\.\S+/.test(data.email)) {
            errors.email = 'Email is not valid';
        }
        if (!/^\d{10}$/.test(data.phoneNumber)) {
            errors.phoneNumber = 'Phone number is not valid';
        }
        return errors;
    };

    const closeModal = () => {
        setShowModal(false);
        navigate(-1); // Go back after form is sent
    };

    return (
        <>
            <Navbar />
            <form className="responsive-form" onSubmit={handleSubmit}>
                <h1 className='form-header'>{props?.title || (state.title || 'Form Title')}</h1>
                <div className="form-group">
                    <label htmlFor="fullName">Full Name</label>
                    <input
                        type="text"
                        id="fullName"
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleChange}
                    />
                    {errors.fullName && <span className="error">{errors.fullName}</span>}
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                    />
                    {errors.email && <span className="error">{errors.email}</span>}
                </div>
                <div className="form-group">
                    <label htmlFor="phoneNumber">Phone Number</label>
                    <input
                        type="tel"
                        id="phoneNumber"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                    />
                    {errors.phoneNumber && <span className="error">{errors.phoneNumber}</span>}
                </div>
                <div className="form-group">
                    <label htmlFor="otherInput">{props?.additionalDetail || (state.additionalDetail || 'Anything else')}</label>
                    <textarea
                        id="otherInput"
                        name="otherInput"
                        value={formData.otherInput}
                        onChange={handleChange}
                    />
                </div>
                <button type="submit">SUBMIT</button>
            </form>

            <Footer />

            {/* Modal for loading and success message */}
            {showModal && <Modal message={modalMessage} onClose={closeModal}/>}
        </>
    );
};

export default ResponsiveForm;
