import './styles.css';

import Footer from '../../components/footer';
import { Link } from 'react-router-dom';
import Navbar from '../../components/navbar';
import React from 'react';

// <img src={require('../../assets/bible-study.jpg')} alt='Bible Study' />
const BibleStudy: React.FC = () => {
    return (
        <>
            <Navbar />
            <div className="main-container-bible-study">
                <div className='left-content'>
                    <img src={require('../../assets/bible-study.jpg')} alt='Bible Study' />
                </div>
                <div className='right-content'>
                    <h1>Bible Study</h1>
                    <p>Our vibrant Bible Study group convenes on the first Saturday of each month, welcoming individuals of all ages and backgrounds to explore the depths of Scripture together. As we gather, we embark on an enriching journey through the pages of the Bible, delving into its teachings, narratives, and wisdom. Through interactive discussions and reflective study, we seek to gain deeper insights into God's Word and its relevance to our lives today. Join us as we seek to apply the timeless wisdom of the Bible to our daily lives, discovering new perspectives and drawing closer to God with each gathering.</p>
                    <div className='btn-div'>
                        <Link to="/form" state={{
                            "title": "We're Excited to Meet and Get to Know You in Our Next Meeting!",
                            "additionalDetail": "Any additional info?"
                        }} >
                            <button>JOIN NEXT MEETING</button>
                        </Link>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default BibleStudy;