import './styles.css';

import Footer from '../footer';
import { Link } from 'react-router-dom';
import Navbar from '../navbar';
import React from 'react';

interface TwoSidedPageProps {
    title? : string;
    description? : string;
    haveLink?: boolean;
    buttonTitle?: string;
    imageUrl?: string
}
const TwoSidedPage = (props: TwoSidedPageProps) => {
    return (
        <>
            <Navbar />
            <div className="main-container-two-sided-page">
                <div className='left-content'>
                    <img src={props.imageUrl} alt='Bible Study' />
                </div>
                <div className='right-content'>
                    <h1>{props.title}</h1>
                    <p>{props.description}</p>
                    <div className='btn-div'>
                       {
                            props.haveLink ? <Link to="/form" state={{
                                "title": "We're Excited to Meet and Get to Know You in Our Next Meeting!",
                                "additionalDetail": "Any additional info?"
                            }} >
                                <button>{props.buttonTitle}</button>
                            </Link> : <></>
                       }
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default TwoSidedPage;