import './styles.css';

import React from 'react'

const Footer: React.FC = () => {
    return (
        <div className="main-container-footer" id='footer'>
            <div className='left-column-footer'>
                <div className='heading-footer'>
                    <h1>Worship With Us<br></br>This Sunday!</h1>
                    <h3>Join us for worship <a href='https://www.youtube.com/@ibadayakiswahilicolumbus3202' style={{ color: 'black', fontSize: '1rem', fontWeight: '700' }}>ONLINE</a> or at our church from <b>1 PM</b>:</h3>
                    <h3 style={{ color: 'black', fontWeight: '500'}}>
                        <br></br>
                        <a  href='https://www.google.com/maps/place/Gethsemane+Lutheran+Church/@40.0743512,-83.0202414,17.02z/data=!4m6!3m5!1s0x88388ceb7582ae01:0x62fd2e3b1e0e1c57!8m2!3d40.0743573!4d-83.0177148!16s%2Fg%2F1tf7n6vw?entry=ttu'
                            style={{textDecoration: 'none', color: 'black'}}
                        >
                            
                                Ibada ya Kiswahili, <br></br>
                                Gethsemane Lutheran Church, <br></br>
                                35 E Staton Ave, <br></br>
                                Columbus, Ohio,<br></br>
                                43214
                          
                        </a> <br></br>
                        <br></br>
                    </h3>
                    <h3><a>
                        (614) 885-4319
                    </a></h3>
                    {/* <br></br>
                    <h3><a>
                        info@iykcolumbusohio.org
                    </a></h3>
                    <br /> */}
                    <br />
                    <h3>Follow us on <a href='https://www.facebook.com/ibadakiswahili.columbus?mibextid=ZbWKwL' style={{color: 'black', fontWeight: '500'}}>Facebook</a></h3>
                    {/* <div className='social-media-icons'>
                        <a>
                            <img
                                src={require('../../assets/icons8-facebook-100.png')}
                            />
                        </a>
                        <a>
                            <img
                                src={require('../../assets/icons8-facebook-100.png')}
                            />
                        </a>
                        <a>
                            <img
                                src={require('../../assets/icons8-facebook-100.png')}
                            />
                        </a>
                        <a>
                            <img
                                src={require('../../assets/icons8-facebook-100.png')}
                            />
                        </a>
                    </div> */}
                    
                    <h6 style={{ fontWeight: 300, fontSize: 12, padding: 0, margin: '20px 0 0 0', color: 'grey' }}>&#169; {new Date().getFullYear()} Ibada ya Kiswahili, Columbus, Ohio</h6>
                </div>
            </div>
            <div className='right-column-footer'>
                <div className='icon-container-footer'>
                    <div className='icon-container-column-footer'>
                        <a href='/services'><h5>Ministries</h5></a>
                        <a href='/groups'><h5>Groups</h5></a>
                        <a href='/bible-study'><h5>Bible study</h5></a>
                        <a href='community'><h5>Community Impact</h5></a>
                        
                    </div>
                    <div className='icon-container-column-footer'>
                        <a  href='/about'><h5>About</h5></a>
                        <a href='/events'><h5>Events</h5></a>
                        <a href='/articles'><h5>Articles</h5></a>
                        <a href='https://www.youtube.com/@ibadayakiswahilicolumbus3202'><h5>Sermons</h5></a>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default Footer;