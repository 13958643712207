import './styles.css';

import React from 'react'

const Ministries: React.FC = () => {
    return (
        <div className="main-container-ministries ">
            <div className='bridge-content-ministries' >
                <h1><u>Join Us</u> in Worshipping God in Swahili, and in Spirit and Truth...</h1>
                <h3>Where we seek to honor Him with sincerity, embracing authentic and heartfelt expressions of faith</h3>
            </div>
            <div className='content-ministries'> 
                <div className='left-column-ministries'>
                    <a className='sunday-services' href='/services' style={{ textDecoration: 'none' }}>
                        <div className='description'>
                            <h1>Ministry Services</h1>
                            <p>Join our diverse ministry services for worship, teaching, and community</p>
                            <h5>EXPLORE</h5>
                        </div>
                    </a>
                    <a className='community-impact' href='/community' style={{ textDecoration: 'none' }}>
                        <div className='description'>
                            <h1>Community Impact</h1>
                            <p>Make a difference through our impactful community programs </p>
                            <h5>EXPLORE</h5>
                        </div>
                    </a>
                </div>
                <div className='right-column-ministries'>
                    <a className='church-groups' href='/groups' style={{ textDecoration: 'none' }}>
                        <div className='description'>
                            <h1>Church Groups</h1>
                            <p>Discover our vibrant church groups and connect with others in faith </p>
                            <h5>EXPLORE</h5>
                        </div>
                    </a>
                    <a className='bible-study' href='/bible-study' style={{ textDecoration: 'none' }}>
                        <div className='description'>
                            <h1>Bible Study</h1>
                            <p>Deepen your faith with our engaging Bible study sessions</p>
                            <h5>EXPLORE</h5>
                        </div>
                    </a>
                </div>
            </div>
            <div className='heading-ministries'>
                <div className='heading-ministries-column' >
                    <h1>We're Moved by a Deeper Desire to <u>Share</u> the Gospel...</h1>
                </div>
                <div className='heading-ministries-column' style={{ borderBottom: 'solid 5px rgb(222, 220, 220)' }}>
                    <h3>
                        Our mission is driven by a profound longing to share the transformative message of the Gospel with all, reaching hearts and communities through heartfelt outreach efforts to foster faith and hope. Guided by our passion for spreading God's love, we engage in purposeful initiatives that bring light to those in need. Join us in this journey of spreading the Gospel's profound impact and making a difference in the world, witnessing lives transformed by the power of Christ's love and grace. Together, let's strive to fulfill the Great Commission, bringing hope and salvation to every corner of our world.
                    </h3>
                </div>
            </div>
        </div>
    )
}

export default Ministries;