import './styles.css';

import Navbar from '../../../components/navbar';
import React from 'react';

const HomeIndex: React.FC = () => {
    return (
        <>
            <div
                className='main-container'
                role='region'
                aria-label='Homepage'
            >
                <Navbar pageName='Home' />
                <video className="background-video" autoPlay loop muted>
                    {/* Update the video source based on your file path or URL */}
                    <source src={require("../../../assets/background-worship.mp4")} type="video/mp4" />
                </video>
                <div className="content">
                    <h1 style={{ textAlign: 'left' }}>Ibada ya Kiswahili</h1>
                    <h2 style={{ textAlign: 'left' }}>Columbus, Ohio</h2>
                    <h3 >scroll down for more...</h3>
                </div>
            </div>
        </>
    );
};

export default HomeIndex;