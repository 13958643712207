export const utils = {
    formatDateTime: (isoDate: string) => {
        const dateObj = new Date(isoDate);

        // Month names
        const monthNames = [
            "January", "February", "March", "April", "May", "June", "July", "August",
            "September", "October", "November", "December"
        ];

        // Get parts of the date
        const month = monthNames[dateObj.getMonth()];
        const day = dateObj.getDate();
        const year = dateObj.getFullYear();

        // Format day with ordinal suffix (e.g., 1st, 2nd, 3rd, etc.)
        const dayWithSuffix = (day: number) => {
            const j = day % 10,
                k = day % 100;
            if (j === 1 && k !== 11) return day + "st";
            if (j === 2 && k !== 12) return day + "nd";
            if (j === 3 && k !== 13) return day + "rd";
            return day + "th";
        };

        // Format time (12-hour format with AM/PM)
        const hours = dateObj.getHours();
        const minutes = dateObj.getMinutes();
        const period = hours >= 12 ? "PM" : "AM";
        const formattedHours = hours % 12 || 12; // Convert to 12-hour format

        // Zero-pad minutes if necessary
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        const formattedDate = `${month} ${dayWithSuffix(day)}, ${year}`;
        const formattedTime = `${formattedHours}:${formattedMinutes} ${period}`;

        return { formattedDate, formattedTime };
    }
}